import { AnimatronProduct } from '@anm/api/modules/auth';
import Container from '@anm/components/Container';
import SmallFooter from '@anm/components/footers/SmallFooter';
import SocialLinksList from '@anm/components/footers/SocialLinksList';
import FooterWrapper, {
  FooterLinksProps,
  FooterSocialWrapper,
  FooterTopSection,
  FooterWrapperProps,
  NavListWrapper,
  SubscribtionAndSocialRow
} from '@anm/components/footers/TopFooter';
import NewsletterBlock from '@anm/components/footers/TopFooter/NewsletterBlock';
import { HrefLinkProps, LinkProps } from '@anm/components/header';
import { pickWaveLinks } from '@anm/data/links';
import getStudioSocialLinks from '@anm/data/links/studio/getStudioSocialLinks';
import { WaveLinkKeys } from '@anm/data/links/wave/getWaveLinks';
import isMobile from '@anm/helpers/is/isMobile';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import vtConfig from '../../config';
import useNavigation from '../../hooks/useNavigation';
import { appMetaSelectors } from '../../store/modules/appMeta';
import { userSelectors } from '../../store/modules/user';

import NavList from './NavList';

const Footer: FC<FooterWrapperProps> = ({
  isNavListOnMobile = false,
  isNewsletterSubscription = false,
  ...restProps
}) => {
  const { footerLinks } = useNavigation();
  const profile = useSelector(userSelectors.selectUserProfile);
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);

  const waveSocialLinks = [
    profile ? 'socialFacebookGroup' : 'socialFacebookDefault',
    'socialYoutube',
    'socialTwitter',
    'socialInstagram',
    'socialLinkedin',
    'socialPinterest',
    'socialTelegram'
  ] as WaveLinkKeys[];

  const { urls, isProd } = vtConfig();
  const socialLinksMap: { [key in AnimatronProduct]: LinkProps[] } = {
    WAVE: pickWaveLinks({ isProd, waveUrl: urls.wave })(waveSocialLinks),
    STUDIO: getStudioSocialLinks(urls.studio)
  };

  const canShowNavList = !isMobile() || isNavListOnMobile;

  return (
    <FooterWrapper {...restProps}>
      <FooterTopSection>
        <Container size={1150}>
          {canShowNavList && (
            <NavListWrapper>
              <NavList footerLinks={footerLinks as FooterLinksProps[]} {...restProps} />
            </NavListWrapper>
          )}
          <SubscribtionAndSocialRow>
            {isNewsletterSubscription && <NewsletterBlock {...restProps} />}
            <FooterSocialWrapper>
              <SocialLinksList socialLinks={socialLinksMap[product] as HrefLinkProps[]} />
            </FooterSocialWrapper>
          </SubscribtionAndSocialRow>
        </Container>
      </FooterTopSection>
      <SmallFooter />
    </FooterWrapper>
  );
};

export default Footer;
