import styled, { css } from 'styled-components';

import { BudgeColors, BudgeForms, BudgeProps } from '.';

const colors: { [key in BudgeColors]: ReturnType<typeof css> } = {
  yellow: css`
    background-color: #ffea00;
    color: #292a2b;
`
};

const forms: { [key in BudgeForms]: ReturnType<typeof css> } = {
  circle: css`
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
`,
  square: css``
};

const BudgeContent = styled.div<BudgeProps>`
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
  ${({ color }) => colors[color]};
  ${({ form }) => forms[form || 'square']};
`;

export default BudgeContent;
