import { Params } from '@anm/components/header';
import { createContext, useState, FC } from 'react';

type Context = {
  params: Params;
  setParams: (params: Params) => void;
};

export const NavigationContext = createContext({} as Context);

export const NavigationProvider: FC = ({ children }) => {
  const [params, setParams] = useState({});

  return (
    <NavigationContext.Provider value={{ params, setParams }}>
      {children}
    </NavigationContext.Provider>
  );
};
