import Button, {
  ButtonProps,
  ButtonSize,
  ButtonVariants
} from '@anm/components/buttons/Button';
import {
  HrefLinkProps,
  LinkProps,
  NavigationLinkOwnProps,
  NavigationLinkProps,
  Params,
  RouteLinkProps,
  WrapperButtonLink
} from '@anm/components/header';
import { isHrefLink } from '@anm/data/links/helpers';
import noop from '@anm/helpers/noop';
import { memo, useContext, FC } from 'react';
import { branch, compose, renderComponent } from 'recompose';

import routes from '../../routes';

import { NavigationContext } from './NavigationContext';

const { Link } = routes;

const HrefLink: FC<HrefLinkProps &
  ButtonProps &
  NavigationLinkOwnProps> = memo(
  ({
    href,
    title,
    size = 'medium' as ButtonSize,
    target = '_self',
    variant = 'link' as ButtonVariants
  }) => <Button {...{ href, size, variant, target }}>{title}</Button>
);

const getInjectedParams = (injectParams: string[], params: Params) =>
  injectParams.reduce(
    (acc, paramName) => ({ ...acc, [paramName]: params[paramName] }),
    {}
  );

const RouteLink: FC<RouteLinkProps & ButtonProps> = memo(
  ({
    route,
    title,
    params,
    size = 'medium' as ButtonSize,
    variant = 'link' as ButtonVariants,
    injectParams = [],
    onClick = noop,
    ...otherProps
  }) => {
    const { params: dynamicParams } = useContext(NavigationContext);

    return (
      <Button
        title={title}
        buttonType="container"
        {...{ size, variant, ...otherProps }}
        onClick={onClick}
      >
        <Link
          route={route}
          params={{
            ...params,
            ...getInjectedParams(injectParams, dynamicParams)
          }}
        >
          <a>{title}</a>
        </Link>
      </Button>
    );
  }
);

const ButtonLink: FC<ButtonProps> = memo(
  ({
    href,
    title,
    size = 'medium' as ButtonSize,
    variant = 'link' as ButtonVariants,
    ...rest
  }) => (
    <WrapperButtonLink {...{ href, size, variant, ...rest }}>
      {title}
    </WrapperButtonLink>
  )
);

export const isButtonLink = (link: LinkProps) =>
  !(link as HrefLinkProps).href && !(link as RouteLinkProps).route;

export default compose<{}, NavigationLinkProps>(
  branch(isButtonLink, renderComponent(ButtonLink)),
  branch(isHrefLink, renderComponent(HrefLink))
)(RouteLink);
