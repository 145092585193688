import { NotificationVariant } from '@anm/components/NotificationBlock';
import NotificationModal from '@anm/components/modals/AppNotificationsModal';
import { memo, useEffect, FC } from 'react';

import useNotifications from './hooks/useNotifications';

type AppNotificationsProps = {
  variant?: NotificationVariant;
};

const AppNotifications: FC<AppNotificationsProps> = props => {
  const [notifications, onRemoveNotification, clearNotifications] = useNotifications();

  useEffect(
    () => () => {
      clearNotifications();
    },
    []
  );

  return <NotificationModal notifications={notifications} onNotificationClose={onRemoveNotification} {...props} />;
};

export default memo(AppNotifications);
