const entries = {
  CALENDAR: ['calendar', 'wave-event'],
  TEMPLATE: [
    'video-templates',
    'video-template',
    'video-category',
    'template-view'
  ]
};

export const getEntryByRouter = (currentRoute: string) =>
  (Object.keys(entries) as ['CALENDAR', 'TEMPLATE']).find(
    key => !!entries[key].find(route => route === currentRoute.replace('/', ''))
  );
