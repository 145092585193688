import { FooterWrapperProps } from '@anm/components/footers/TopFooter';
import { NavigationVariants } from '@anm/components/header';
import AppNotifications from '@anm/components/notification/AppNotifications';
import * as semrush from '@anm/helpers/semrush';
import { useContext, FC } from 'react';

import Navigation from '../../Navigation';
import NotificationModal from '../../notification/NotificationModal';

import { LayoutContext } from './LayoutContext';
import LayoutFooter, { FooterVariants } from './LayoutFooter';
import Main, { MainVariants } from './Main';

export { LayoutProvider } from './LayoutContext';

export type LayoutProps = {
  hasShadow?: boolean;
  mainVariant?: MainVariants;
  footer?: FooterWrapperProps;
  footerVariant?: FooterVariants;
  navigationVariant?: NavigationVariants;
  renderNavigation?: () => JSX.Element | null;
};

const Layout: FC<LayoutProps> = ({
  footer,
  children,
  mainVariant,
  hasShadow = false,
  footerVariant = 'big',
  navigationVariant,
  renderNavigation = () => <Navigation {...{ hasShadow }} variant={navigationVariant || 'blue'} />
}) => {
  const { isNavigation, isFooter } = useContext(LayoutContext);

  const isSemrush = semrush.isSemrushIframe();

  return (
    <>
      <NotificationModal />
      <AppNotifications />
      {isNavigation && !isSemrush && renderNavigation()}
      <Main variant={mainVariant || 'cover'}>{children}</Main>
      {isFooter && !isSemrush && <LayoutFooter variant={footerVariant} {...footer} />}
    </>
  );
};
export default Layout;
