import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appNotificationActions, appNotificationSelectors } from '../../../../store/modules/appNotifications';

const useNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(appNotificationSelectors.selectAppNotifications);

  const onRemoveNotification = useCallback(
    (id: string) => {
      const currentNotification = notifications.find(n => n.id === id);
      currentNotification?.onModalClose?.();
      dispatch(appNotificationActions.removeSideNotification(id));
    },
    [notifications]
  );

  const clearNotifications = useCallback(() => dispatch(appNotificationActions.cleanNotifications()), []);

  return [notifications, onRemoveNotification, clearNotifications] as const;
};

export default useNotifications;
