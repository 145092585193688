import styled from 'styled-components';

import { SidebarModalProps } from '.';

const SidebarContent = styled.div`
  width: ${({ width }: Partial<SidebarModalProps>) => width};
  background: ${({ background }: Partial<SidebarModalProps>) => background};

  pointer-events: all;
  overflow-y: auto;
  height: 100%;
`;

export default SidebarContent;
