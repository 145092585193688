import { DOMAttributes, FC } from 'react';

import BudgeContent from './BudgeContent';
import BudgeWrapper from './Wrapper';

export type BudgeForms = 'circle' | 'square';
export type BudgeColors = 'yellow';
export interface BudgeProps {
  color: BudgeColors;
  badgeContent: React.ReactNode;
  form?: BudgeForms;
}

const Budge: FC<BudgeProps & DOMAttributes<Element>> = props => {
  const { badgeContent, children } = props;
  return (
    <BudgeWrapper {...props}>
      <BudgeContent {...props}>{badgeContent}</BudgeContent>
      {children}
    </BudgeWrapper>
  );
};

export default Budge;
