import NotificationBlock, { NotificationBlockProps, NotificationVariant } from '@anm/components/NotificationBlock';
import { memo, useCallback, FC } from 'react';

import Content from '../NotificationModal/Content';
import NotificationPortal from '../NotificationModal/Portal';

type TNotification = NotificationBlockProps & { id: string };

export type ModalProps = {
  variant?: NotificationVariant;
  notifications: TNotification[];
  onNotificationClose: (id: string) => void;
};

type NotificationProps = TNotification & {
  onNotificationClose: (id: string) => void;
};

const Notification: FC<NotificationProps> = props => {
  const { id, onNotificationClose } = props;
  const deleteNotification = useCallback(() => onNotificationClose(id), [id]);

  return <NotificationBlock {...props} onModalClose={deleteNotification} />;
};

const AppNotificationsModal: FC<ModalProps> = ({ notifications, onNotificationClose, variant = 'aside' }) => (
  <NotificationPortal>
    <Content variant="aside">
      {notifications.map(n => (
        <Notification key={n.id} {...{ ...n, variant, onNotificationClose }} />
      ))}
    </Content>
  </NotificationPortal>
);

export default memo(AppNotificationsModal);
