import { rightToLeftMenuAppearing } from '@anm/styles/helpers';
import styled from 'styled-components';

import { SidebarModalProps } from '.';

const SidebarModalWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: start;
  justify-content: ${({ direction }: Partial<SidebarModalProps>) =>
    direction === 'right' ? 'flex-end' : 'flex-start'};
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(100%);
  ${rightToLeftMenuAppearing};
`;

export default SidebarModalWrapper;
