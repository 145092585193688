import { AnimatronProduct } from '@anm/api/modules/auth';
import { ButtonVariants } from '@anm/components/buttons/Button';
import { LinkProps } from '@anm/components/header';
import { getStudioLinks, getWaveLinks } from '@anm/data/links';
import { FC } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import withSpinnerWhileUserLoadingUser, { WithSpinnerUser } from '../../HOCs/withSpinnerWhileUserLoadingUser';
import vtConfig from '../../config';
import MyProjectsButton from '../user/MyProjectsButton';

import UserDropdownMenu from './UserMenu';

type HOCs = WithSpinnerUser;
type UserLoggedButtonsProps = { buttonVariant: ButtonVariants; hideMyProjectsButton?: boolean };

const UserLoggedButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const { urls, isProd } = vtConfig();
export const myProjectsLinkMap: { [key in AnimatronProduct]: LinkProps } = {
  WAVE: getWaveLinks({ isProd, waveUrl: urls.wave }).myProjects,
  STUDIO: getStudioLinks(urls.studio).myProjects
};

const UserLoggedButtons: FC<UserLoggedButtonsProps & HOCs> = ({
  user: {
    profile: { email, displayName }
  },
  buttonVariant,
  hideMyProjectsButton = false
}) => (
  <UserLoggedButtonsWrapper>
    {!hideMyProjectsButton && <MyProjectsButton buttonVariant={buttonVariant} />}
    <UserDropdownMenu {...{ email, displayName }} />
  </UserLoggedButtonsWrapper>
);

export default compose<UserLoggedButtonsProps & HOCs, UserLoggedButtonsProps>(withSpinnerWhileUserLoadingUser)(
  UserLoggedButtons
);
