import { ButtonProps } from '@anm/components/buttons/Button';
import { FC } from 'react';

import LoginButton from './LoginButton';
import SignUpButton from './SignUpButton';
import AuthButtonsWrapper from './Wrapper';

type ButtonType = 'login' | 'sign-up';

export type ButtonItem = ButtonProps & {
  name: ButtonType;
};
export type AuthButtonsProps = {
  buttons: ButtonItem[];
};

const renderButton = ({ name, ...buttonProps }: ButtonItem) => {
  switch (name) {
    case 'login':
      return <LoginButton key={name} {...buttonProps} />;

    case 'sign-up':
      return <SignUpButton key={name} {...buttonProps} />;
  }
};

const AuthButtons: FC<AuthButtonsProps> = ({ buttons }) => (
  <AuthButtonsWrapper>
    {buttons.map(buttonItem => renderButton(buttonItem))}
  </AuthButtonsWrapper>
);

export default AuthButtons;
