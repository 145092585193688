import SmallFooter from '@anm/components/footers/SmallFooter';
import { branch, compose, renderComponent } from 'recompose';

import Footer from '../../Footer';

type LayoutFooterProps = {
  variant: FooterVariants;
};

export type FooterVariants = 'big' | 'small' | 'no-footer';

const noFooter = ({ variant }: LayoutFooterProps) => variant === 'no-footer';
const isSmallFooter = ({ variant }: LayoutFooterProps) => variant === 'small';

export default compose<{}, LayoutFooterProps>(
  branch(
    noFooter,
    renderComponent(() => null)
  ),
  branch(isSmallFooter, renderComponent(SmallFooter))
)(Footer);
