import { AnimatronProduct } from '@anm/api/modules/auth';
import { ButtonVariants } from '@anm/components/buttons/Button';
import { LinkProps } from '@anm/components/header';
import { getStudioLinks, getWaveLinks } from '@anm/data/links';
import { FC } from 'react';
import styled from 'styled-components';

import withAppMeta, { WithAppMetaProps } from '../../../HOCs/withAppMeta';
import vtConfig from '../../../config';
import NavigationLink from '../../Navigation/NavigationLink';

type HOCs = WithAppMetaProps;
export type MyProjectsButtonProps = {
  buttonVariant: ButtonVariants;
};
const { urls, isProd } = vtConfig();

export const myProjectsLinkMap: { [key in AnimatronProduct]: LinkProps } = {
  WAVE: getWaveLinks({ isProd, waveUrl: urls.wave }).myProjects,
  STUDIO: getStudioLinks(urls.studio).myProjects
};

const Wrapper = styled.div`
  margin-right: 24px;
  a {
    color: inherit;
    min-height: 40px;
  }
  & > * {
    min-width: 143px;
    padding: 0;
  }
`;

const MyProjectsButton: FC<HOCs & MyProjectsButtonProps> = ({ appMeta: { product }, buttonVariant }) => (
  <Wrapper data-button="myProjectsButton">
    <NavigationLink {...myProjectsLinkMap[product]} variant={buttonVariant} size="medium" />
  </Wrapper>
);

export default withAppMeta(MyProjectsButton);
