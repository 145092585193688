// import { frontChat } from '@anm/helpers/frontChat';
import { LiveStreamKind } from 'types/stream';

const trackIntercom = (eventName: string, metadata?: {}) => {
  console.info('trackIntercom', eventName, metadata);
  // frontChat('trackEvent', eventName, metadata) // TODO maybe we need in the future
};
const trackIntercomKind = (eventName: string) => (kind: LiveStreamKind) => trackIntercom(eventName, { type: kind });

export const trackIntercomStudioOpen = () => {
  trackIntercom('app.studio.open');
};
export const trackIntercomStreamStart = trackIntercomKind('app.studio.stream.start');
export const trackIntercomStreamCreate = trackIntercomKind('app.studio.stream.create');
