import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';
import Button from '@anm/components/buttons/Button/Button';

const AuthButtonsMobile = media.tablet`
    padding: 20px;
    justify-content: space-between;
    > * {
        width: 45%;
        text-align: center;
        button {
            width: 100%;
        }
    }
`;

const AuthButtonsWrapper = styled.div`
  white-space: nowrap;
  ${flexCenter};
  ${AuthButtonsMobile};
  ${Button} {
    min-width: 110px;
  }
  a + a {
    margin-left: 20px;
  }
`;

export default AuthButtonsWrapper;
