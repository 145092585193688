import { AnimatronProduct } from '@anm/api/modules/auth';

import { getEntryByRouter } from '../../../../helpers/authEntry';
import routes from '../../../../routes';
import { getAuthParamsObject, isEditorEmbed } from '../helpers';

const { Router } = routes;

export const getParams = () => {
  const signUpEntry = getEntryByRouter(Router!.router!.route);
  const authParamsObject = getAuthParamsObject();

  return {
    ...authParamsObject,
    ...(signUpEntry && { entry: signUpEntry })
  };
};

const isEditorLogin = () => /editor\/login/.test(Router.router!.route);

export const getRoute = (productName: AnimatronProduct) => {
  switch (true) {
    case isEditorEmbed():
      return 'signup-embed';
    case isEditorLogin():
      return 'editor-signup';
    case productName === 'STUDIO':
      return 'studio-signup';
    default:
      return 'signup';
  }
};
