import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { LogoVariant } from '@anm/components/Logo';
import Button, { ButtonVariants } from '@anm/components/buttons/Button';
import {
  DesktopNavigation,
  MobileMenuButtonWrapper,
  NavigationLinkProps,
  NavigationOwnProps,
  NavigationVariants,
  NavigationWrapper
} from '@anm/components/header';
import MobileNavigation from '@anm/components/header/MobileNavigation';
import NavigationList from '@anm/components/header/NavigationList';
import noop from '@anm/helpers/noop';
import * as semrush from '@anm/helpers/semrush';
import useScrolledOut from '@anm/hooks/useScrolledOut';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, FC } from 'react';

import useNavigation from '../../hooks/useNavigation';
import Logo from '../Logo';
import SidebarModal from '../SidebarModal';
import AuthUserMenuBlock, { AuthUserMenuBlockVariants } from '../auth/AuthUserMenuBlock';
import NotificationAlert from '../notification/NotificationAlert';

import UserLinksList from './MobileNavigation/UserLinksList';
import UserMenu from './MobileNavigation/UserMenu';
import NavigationLink from './NavigationLink';
import styled from 'styled-components';

const logoVariantMap: { [key in NavigationVariants]: LogoVariant } = {
  pink: 'studio_logo_black',
  blue: 'wave',
  white: 'dark-wave',
  grey: 'dark-wave',
  'white-hosting': 'wave-hosting',
  live: 'live',
  lives: 'lives',
  'blue-light': 'dark-wave',
  transparent: 'wave'
};

const mobileMenuButtonVariantMap: {
  [key in NavigationVariants]: ButtonVariants;
} = {
  pink: 'white-black-border-thin',
  blue: 'border-transparent',
  white: 'silver-border',
  grey: 'silver-border',
  'white-hosting': 'silver-border',
  live: 'silver-border',
  lives: 'silver-border',
  'blue-light': 'silver-border',
  transparent: 'silver-border'
};

const authUserMenuBlockMap: {
  [key in NavigationVariants]: AuthUserMenuBlockVariants;
} = {
  pink: 'white',
  blue: 'white',
  white: 'blue',
  grey: 'blue',
  'white-hosting': 'blue',
  live: 'blue',
  lives: 'blue',
  'blue-light': 'blue',
  transparent: 'white'
};

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Navigation: FC<NavigationOwnProps> = ({
  variant,
  hasShadow,
  hideMyProjectsButton = false,
  renderAuthBlock = () => (
    <AuthUserMenuBlock
      size="medium"
      variant={authUserMenuBlockMap[variant]}
      hideMyProjectsButton={hideMyProjectsButton}
    />
  ),
  renderInnerContent = noop,
  renderAdditionalButtons = noop
}) => {
  const [isModalOpened, openModal, closeModal] = useSwitchState();
  const { navList } = useNavigation();
  const isScrolled = useScrolledOut(30);

  const renderLogo = useCallback(() => <Logo variant={logoVariantMap[variant]} />, []);
  const renderUserLinkList = useCallback(() => <UserLinksList />, []);
  const renderUserMenu = useCallback(
    (onUserBlockClick: () => void) => <UserMenu variant={variant} {...{ onUserBlockClick }} />,
    []
  );
  const renderNavLink = useCallback((params: NavigationLinkProps) => <NavigationLink {...params} />, []);

  const isSemrush = semrush.isSemrushIframe();

  const isLiveHeader = ['live', 'lives'].includes(variant);

  return (
    <>
      {isModalOpened && (
        <SidebarModal width="100%" direction="right" background="#fff" onModalClose={closeModal}>
          <MobileNavigation
            list={navList}
            onModalClose={closeModal}
            {...{
              renderLogo,
              renderNavLink,
              renderUserMenu,
              renderUserLinkList
            }}
          />
        </SidebarModal>
      )}

      <NavigationWrapper isNoLogo={isSemrush} {...{ hasShadow, variant, isScrolled }}>
        {!isSemrush && <Logo variant={logoVariantMap[variant]} />}
        <DesktopNavigation isNoLinks={isLiveHeader}>
          {!isLiveHeader && (
            <nav>
              <NavigationList
                list={navList}
                variant={variant}
                renderNavLink={(params: NavigationLinkProps) => <NavigationLink {...params} />}
              />
            </nav>
          )}
          <FlexWrapper>
            {renderInnerContent()}
            {hasAuthAccess() && <NotificationAlert />}
            {renderAuthBlock()}
            {renderAdditionalButtons()}
          </FlexWrapper>
        </DesktopNavigation>

        <MobileMenuButtonWrapper onClick={openModal}>
          <Button variant={mobileMenuButtonVariantMap[variant]} size="medium">
            Menu
          </Button>
        </MobileMenuButtonWrapper>
      </NavigationWrapper>
    </>
  );
};

export default Navigation;
