import { createContext } from 'react';

type LayoutContextProps = {
  isNavigation?: boolean;
  isFooter?: boolean;
};

export const LayoutContext = createContext({
  isNavigation: true,
  isFooter: true
} as LayoutContextProps);

export const LayoutProvider = LayoutContext.Provider;
