import { FC } from 'react';

import withNotificationAlertHandlers, {
  WithNotificationAlertHandlersProps
} from '../../../HOCs/withNotificationAlertHandlers';
import Budge from '../../Budge';

import RingIcon from './RingIcon';
import NotificationAlertWrapper from './Wrapper';

export interface NotificationAlertProps
  extends WithNotificationAlertHandlersProps { }

const NotificationAlert: FC<NotificationAlertProps> = ({
  notification: { count },
  handleAlertClick
}) => {
  if (!count) return null;
  return (
    <NotificationAlertWrapper data-button="notificationAlert">
      <Budge
        form="circle"
        color="yellow"
        badgeContent={count}
        onClick={handleAlertClick}
      >
        <RingIcon />
      </Budge>
    </NotificationAlertWrapper>
  );
};

export default withNotificationAlertHandlers(NotificationAlert);
