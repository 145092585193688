import { FC } from 'react';

import withSpinnerWhileUserLoadingUser, { WithSpinnerUser } from '../../HOCs/withSpinnerWhileUserLoadingUser';
import UserAvatar from '../UserAvatar';

import UserInfo, { UserEmailInfo } from './UserInfo';
import UserBlockWrapper from './Wrapper';

type UserBlockProps = WithSpinnerUser & { className: string };

const UserBlock: FC<UserBlockProps> = ({
  className,
  user: {
    profile: { email, displayName }
  }
}) => 
    <UserBlockWrapper className={className}>
      <UserAvatar />
      <UserInfo>
        <span>{displayName}</span>
        <UserEmailInfo>{email}</UserEmailInfo>
      </UserInfo>
    </UserBlockWrapper>

export default withSpinnerWhileUserLoadingUser(UserBlock);
