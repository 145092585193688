import { AnimatronProduct } from '@anm/api/modules/auth';
import { LinkProps, MenuDivider } from '@anm/components/header';
import { studioUserMenuLinks, waveUserMenuLinks } from '@anm/data/links';
import { FC } from 'react';
import { compose, mapProps } from 'recompose';
import { useSelector } from 'react-redux';

import withAppMeta, { WithAppMetaProps } from '../../../HOCs/withAppMeta';
import vtConfig from '../../../config';
import NavigationLink from '../../Navigation/NavigationLink';
import LogoutButton from '../../auth/LogoutButton';
import { userSelectors } from '../../../store/modules/user';

type UserMenuListProps = {
  menuLinkList: LinkProps[];
};
const { urls, isProd } = vtConfig();

const menuLinkListMap: { [key in AnimatronProduct]: LinkProps[] } = {
  WAVE: waveUserMenuLinks({ isProd, waveUrl: urls.wave }),
  STUDIO: studioUserMenuLinks(urls.studio)
};

const UserMenuListItem: FC<LinkProps> = props => {
  const canLiveStream = useSelector(userSelectors.selectCanLiveStream);

  if (!canLiveStream && props.name === 'myStream') return null;
  return (
    <div key={props.title}>
      <li>
        <NavigationLink {...props} />
      </li>
      {props.name === 'myStream' && <MenuDivider key="divider" />}
    </div>
  );
};

const UserMenuList: FC<UserMenuListProps> = ({ children, menuLinkList }) => (
  <ul>
    {children}

    {menuLinkList.map(UserMenuListItem)}

    <li key="logout-button">
      <LogoutButton />
    </li>
  </ul>
);

const UserMenuLinksList = compose<UserMenuListProps, {}>(
  withAppMeta,
  mapProps<UserMenuListProps, WithAppMetaProps>(({ appMeta: { product }, ...otherProps }) => ({
    menuLinkList: menuLinkListMap[product],
    ...otherProps
  }))
)(UserMenuList);

export default UserMenuLinksList;
