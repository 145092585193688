import { ButtonSize, ButtonVariants } from '@anm/components/buttons/Button';
import { FC } from 'react';

import UserMenuBlock from '../../UserMenuBlock';
import AuthBlock from '../AuthBlock';
import AuthButtons from '../AuthButtons';

export type AuthUserMenuBlockVariants = 'pink' | 'blue' | 'white' | 'mobile_white';

type AuthUserMenuBlockProps = {
  size: ButtonSize;
  variant: AuthUserMenuBlockVariants;
  renderUserMenuBlock?: () => JSX.Element;
  hideMyProjectsButton?: boolean;
};

const loginVariantMap: {
  [key in AuthUserMenuBlockVariants]: ButtonVariants;
} = {
  pink: 'link-with-dash',
  blue: 'silver-border',
  white: 'white-white-border-thin',
  mobile_white: 'dark-blue'
};

const signUpVariantMap: {
  [key in AuthUserMenuBlockVariants]: ButtonVariants;
} = {
  pink: 'link',
  blue: 'yellow',
  white: 'yellow',
  mobile_white: 'red'
};

const myProjectButtonVariantMap: {
  [key in AuthUserMenuBlockVariants]: ButtonVariants;
} = {
  pink: 'link-with-dash',
  blue: 'silver-border',
  white: 'white-white-border-thin',
  mobile_white: 'white-white-border-thin'
};

const renderAuthButtons = (variant: AuthUserMenuBlockVariants, size: ButtonSize) => () => (
  <AuthButtons
    buttons={[
      {
        size,
        name: 'login',
        variant: loginVariantMap[variant]
      },
      {
        size,
        name: 'sign-up',
        variant: signUpVariantMap[variant]
      }
    ]}
  />
);

const AuthUserMenuBlock: FC<AuthUserMenuBlockProps> = ({
  size,
  variant,
  hideMyProjectsButton = false,
  renderUserMenuBlock = () => (
    <UserMenuBlock buttonVariant={myProjectButtonVariantMap[variant]} hideMyProjectsButton={hideMyProjectsButton} />
  )
}) => <AuthBlock renderUserBlock={renderUserMenuBlock} renderAuthButtons={renderAuthButtons(variant, size)} />;

export default AuthUserMenuBlock;
