import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT_MOBILE, FOOTER_HEIGHT_MOBILE } from '@anm/styles/constants';

type MainProps = {
  variant: MainVariants;
};

export type MainVariants = 'cover' | 'contain';

const coverMobile = media.phone`
  min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE} - ${FOOTER_HEIGHT_MOBILE});
`;

const variants: { [key in MainVariants]: ReturnType<typeof css> } = {
  cover: css`
    min-height: 100vh;
    ${coverMobile};
  `,
  contain: css``
};

const Main = styled.main<MainProps>`
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  ${({ variant }) => variants[variant]};
`;

export default Main;
