import {
  HrefLinkProps,
  LinkProps,
  RouteLinkProps
} from '@anm/components/header';

export const isHrefLink = (link: LinkProps): link is HrefLinkProps =>
  !!(link as HrefLinkProps).href;

export const isRouteLink = (link: LinkProps): link is RouteLinkProps =>
  !!(link as RouteLinkProps).route;
