import Avatar from '@anm/components/Avatar';
import { FC } from 'react';

import withSpinnerWhileUserLoadingUser, {
  WithSpinnerUser
} from '../../HOCs/withSpinnerWhileUserLoadingUser';
import vtConfig from '../../config';

const userPageUrl = `${vtConfig().urls.wave}videos`;

const UserAvatar: FC<WithSpinnerUser> = ({
  user: {
    profile: { avatar }
  }
}) => <Avatar src={avatar} size={40} href={userPageUrl} alt="User Avatar" />;

export default withSpinnerWhileUserLoadingUser(UserAvatar);
