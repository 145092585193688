import Loader from '@anm/components/Loader';
import isServer from '@anm/helpers/is/isServer';
import { branch, compose, renderComponent, Omit } from 'recompose';
import { User } from 'user';

import { UserState } from '../store/modules/user';

import withAuth, { WithAuth } from './withAuth';
import withUser, { WithUserProps } from './withUser';

type NewUserState = Omit<UserState, 'profile'> & {
  profile: User;
};

export type WithSpinnerUser = { user: NewUserState };

const isUserExist = ({ user: { profile, isPending } }: WithUserProps) =>
  !profile && !isPending;

const isUserLoading = ({ auth, user }: WithAuth & WithUserProps) =>
  !user.profile && (isServer() || auth.isPending || user.isPending);

export default compose(
  withAuth,
  withUser,
  branch(isUserLoading, renderComponent(Loader)),
  branch(
    isUserExist,
    renderComponent(() => null)
  )
);
