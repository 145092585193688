import { mobileMenuItem } from '@anm/styles/helpers';
import { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { authActions } from '../../../store/modules/auth';

const LogoutButtonWrapper = styled.span`
  ${mobileMenuItem};
  height: 45px;
  line-height: 45px;
`;

const LogoutButton: FC = () => {
  const dispatch = useDispatch();
  const logout = useCallback(() => {
    dispatch(authActions.logout());
  }, []);

  return <LogoutButtonWrapper onClick={logout}>Log Out</LogoutButtonWrapper>;
};

export default LogoutButton;
