import { isAnon } from '@anm/auth/helpers/authAnon';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { useEffect, useState, FC } from 'react';
import { compose } from 'recompose';

import withAuth, { WithAuth } from '../../../HOCs/withAuth';
import withUser, { WithUserProps } from '../../../HOCs/withUser';

type HOCs = WithAuth & WithUserProps;
type AuthBlockProps = {
  renderUserBlock: () => JSX.Element;
  renderAuthButtons: () => JSX.Element;
};

const AuthBlock: FC<HOCs & AuthBlockProps> = ({ auth, user, renderUserBlock, renderAuthButtons }) => {
  const [Content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const isUserPending = !user.profile && user.isPending;
    const isPending = auth.isPending || isUserPending;
    const renderContent = hasAuthAccess() && user.profile && !isAnon() ? renderUserBlock : renderAuthButtons;

    setContent(isPending ? null : renderContent());
  }, [user.profile, auth.isPending, user.isPending]);

  return Content;
};

export default compose<HOCs, AuthBlockProps>(withAuth, withUser)(AuthBlock);
