import getFromCdn from '@anm/helpers/getFromCdn';
import { ringingBell } from '@anm/styles/helpers';
import styled from 'styled-components';

export default styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url("${getFromCdn(
    'images/ring-alert-icon.svg'
  )}") no-repeat 0 0 transparent;
  z-index: 0;
  will-change: transform;
  &:hover {
    ${ringingBell};
  }
`;
