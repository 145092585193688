import styled from 'styled-components';
import { media } from '@anm/styles/helpers';

const notificationAlertWrapperTablet = media.desktop`
  display: none;
`;

const NotificationAlertWrapper = styled.div`
  margin-right: 27px;
  ${notificationAlertWrapperTablet};
`;

export default NotificationAlertWrapper;
