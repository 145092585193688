import { ComponentType } from 'react';
import { compose, withHandlers } from 'recompose';

import withNotification, { WithNotificationProps } from './withNotification';

type HOCs = WithNotificationProps;
type Handlers = {
  handleAlertClick: () => void;
};
export interface WithNotificationAlertHandlersProps extends Handlers, HOCs {}

const withNotificationAlertHandlers = <P>(
  BaseComponent: ComponentType<WithNotificationAlertHandlersProps & P>
) => {
  type BaseComponentOwnProps = Pick<
    P,
    Exclude<keyof P, keyof WithNotificationAlertHandlersProps>
  >;

  return compose<WithNotificationAlertHandlersProps & P, BaseComponentOwnProps>(
    withNotification,
    withHandlers<WithNotificationAlertHandlersProps, Handlers>({
      handleAlertClick: ({
        notification: { notifications },
        notificationActions: { activate }
      }) => () => {
        activate(notifications[0]);
      }
    })
  )(BaseComponent);
};

export default withNotificationAlertHandlers;
