import { NavigationVariants } from '@anm/components/header';
import { IconArrow, MyAccount, MyAccountMenuItem } from '@anm/components/header/MobileNavigation/Wrapper';
import { DOMAttributes, FC } from 'react';

import UserAvatar from '../../UserAvatar';
import AuthUserMenuBlock, { AuthUserMenuBlockVariants } from '../../auth/AuthUserMenuBlock';

export type MobileNavigationUserBlockProps = DOMAttributes<Element> & {};

const MobileNavigationUserBlock: FC<MobileNavigationUserBlockProps> = ({ onClick }) => (
  <MyAccountMenuItem>
    <UserAvatar />
    <MyAccount onClick={onClick}>
      <span>Account</span>
      <IconArrow />
    </MyAccount>
  </MyAccountMenuItem>
);

export type UserMenuProps = {
  onUserBlockClick(): void;
  variant: NavigationVariants;
};

const authUserMenuBlockVariantsMap: {
  [key in NavigationVariants]: AuthUserMenuBlockVariants;
} = {
  pink: 'mobile_white',
  blue: 'blue',
  'blue-light': 'blue',
  transparent: 'blue',
  grey: 'blue',
  white: 'blue',
  'white-hosting': 'blue',
  live: 'blue',
  lives: 'blue'
};

const UserMenu: FC<UserMenuProps> = ({ variant, onUserBlockClick }) => (
  <AuthUserMenuBlock
    size="upper-medium"
    variant={authUserMenuBlockVariantsMap[variant]}
    renderUserMenuBlock={() => <MobileNavigationUserBlock onClick={onUserBlockClick} />}
  />
);

export default UserMenu;
