import { AnimatronProduct } from '@anm/api/modules/auth';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { FooterLinksProps } from '@anm/components/footers/TopFooter';
import { NavigationLinkWithNested } from '@anm/components/header';
import { VIDEO_TEMPLATE_ROUTE } from '@anm/constants/routes';
import { getWaveLinks as waveLinksData } from '@anm/data/links';
import * as intercom from '@anm/helpers/intercom';
import isMobile from '@anm/helpers/is/isMobile';
import { useSelector } from 'react-redux';

import vtConfig from '../config';
import { appMetaSelectors } from '../store/modules/appMeta';
import { authSelectors } from '../store/modules/auth';

type NavigationProps = {
  footerLinks: FooterLinksProps[];
  navList: NavigationLinkWithNested[];
};

type LinksProps = LinkMapProps & {
  product: AnimatronProduct;
};

type LinkMapProps = {
  isUserLogged: boolean;
};

const { urls, isProd } = vtConfig();
const { wave: waveUrl, studio: studioUrl, animatronRoot: animatronUrl } = urls;
const { termsUse, privacyPolicy, cookiePolicy } = waveLinksData({ isProd, waveUrl });

const getWaveLinks = ({ isUserLogged }: LinkMapProps): NavigationProps => ({
  footerLinks: [
    {
      menuTitle: 'company',
      links: [
        {
          title: 'About',
          href: `${waveUrl}about`
        },
        {
          title: 'Contact Us',
          onClick: intercom.openChat
        },
        privacyPolicy,
        termsUse,
        cookiePolicy,
        {
          title: 'Jobs',
          href: 'https://www.notion.so/Wave-video-Jobs-40a951c3496141e7a1506e48426ef478',
          target: '_blank'
        },
        {
          title: 'Partner with Us',
          href: `${waveUrl}partner`
        }
      ]
    },
    {
      menuTitle: 'Use cases',
      links: [
        {
          title: 'Facebook Video Cover',
          href: `${waveUrl}facebook-cover-video`
        },
        {
          title: 'Text on Video',
          href: `${waveUrl}tools/video-editing/add-text-to-video`
        },
        {
          title: 'Intro and Outro',
          href: `${waveUrl}assets/effects/video-intro-maker`
        },
        {
          title: 'Video in Email',
          href: `${waveUrl}video-email`
        },
        {
          title: 'Combine Video Clips',
          href: `https://wave.video/blog/combine-video-clips/`
        },
        {
          title: 'Add Gifs to Videos',
          href: `https://wave.video/blog/add-gifs-to-videos/`
        }
      ]
    },
    {
      menuTitle: 'solutions',
      links: [
        {
          title: 'Social Media Video Maker',
          href: `${waveUrl}social-media-video-maker`
        },
        {
          title: 'Instagram Video Editor',
          href: `${waveUrl}tools/video-marketing/instagram`
        },
        {
          title: 'Video Ads Maker',
          href: `${waveUrl}ad-maker`
        },
        {
          title: 'Animated Text Generator',
          href: `${waveUrl}animated-text`
        },
        {
          title: 'Online Video Maker',
          href: `${waveUrl}online-video-maker`
        },
        {
          title: 'Video Marketing',
          href: `${waveUrl}video-marketing-platform`
        }
      ]
    },
    {
      menuTitle: 'resources',
      links: [
        {
          title: 'Facebook Video Sizes',
          href: `https://wave.video/blog/facebook-video-specs/`
        },
        {
          title: 'Instagram Video Formats',
          href: `https://wave.video/blog/video-format-for-instagram/`
        },
        {
          title: 'YouTube Video Ideas',
          href: `https://wave.video/blog/youtube-video-ideas/`
        },
        {
          title: 'Free Stock Video List',
          href: `https://wave.video/blog/free-stock-footage/`
        },
        {
          title: 'Royalty Free Music Sites',
          href: `https://wave.video/blog/royalty-free-music/`
        },
        {
          title: 'Animated Text Generators',
          href: `https://wave.video/blog/best-animated-text-generators/`
        },
        {
          title: 'Video Editing Tutorials',
          href: `${waveUrl}tutorial`
        }
      ]
    },
    {
      menuTitle: 'Reads',
      links: [
        {
          title: 'How to Make a Video Storyboard',
          href: `https://wave.video/blog/what-is-a-storyboard/`
        },
        {
          title: 'Make Your Videos Look Professional',
          href: `https://wave.video/blog/12-simple-tips-for-making-your-videos-look-more-professional/`
        },
        {
          title: 'Instagram Story Video Guide',
          href: `https://wave.video/blog/instagram-story-video/`
        },
        {
          title: 'How to Rotate a Video',
          href: `https://wave.video/blog/4-easy-ways-to-rotate-a-video/`
        },
        {
          title: 'Make YouTube Videos',
          href: `${waveUrl}how-to-make-youtube-videos`
        },
        {
          title: 'Make Instagram Videos',
          href: `${waveUrl}tools/video-marketing/instagram`
        }
      ]
    }
  ],
  navList: [
    isMobile() &&
      hasAuthAccess() && {
        title: 'My projects',
        route: 'my-projects'
      },
    {
      title: 'Live streaming',
      href: '/live-streaming',
      isNew: true,
      isDropdownWithPreview: true,
      children: [
        {
          links: [
            {
              title: 'Multistreaming',
              href: `${waveUrl}multi-streaming`
            },
            {
              title: 'Video recorder',
              href: `${waveUrl}live-streaming/online-video-recorder`
            },
            {
              title: 'Webcam test',
              href: `${waveUrl}webcam-test`
            },
            {
              title: 'Live stream chat',
              href: `${waveUrl}live-streaming/chat`
            },
            {
              title: 'Live streaming studio',
              href: `${waveUrl}live-streaming/studio`
            },
            {
              title: 'Webcam recorder',
              href: `${waveUrl}live-streaming/webcam-recorder`
            }
          ]
        },
        {
          links: [
            {
              title: 'Live streaming software',
              href: `${waveUrl}live-streaming/streaming-software`
            },
            {
              title: 'Streaming overlay maker',
              href: `${waveUrl}live-streaming/live-stream-overlay-maker`
            },
            {
              title: 'Facebook live streaming',
              href: `${waveUrl}live-streaming/facebook-live-streaming-service`
            },
            {
              title: 'YouTube live streaming',
              href: `${waveUrl}live-streaming/youtube-live-streaming-service`
            },
            {
              title: 'Co stream',
              href: `${waveUrl}live-streaming/co-streaming`
            },
            {
              title: 'Online meetings',
              href: `${waveUrl}live-streaming/online-meetings`
            }
          ]
        }
      ]
    },
    {
      title: 'Templates',
      route: 'video-templates',
      isDropdownWithPreview: true,
      children: [
        {
          title: 'Live streaming',
          route: VIDEO_TEMPLATE_ROUTE,
          params: {
            pageName: 'live-streaming'
          },
          links: [
            {
              title: 'Countdown',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'live-streaming-countdown'
              }
            },
            {
              title: 'Lower Third',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'live-streaming-trn-lower-third'
              }
            },
            {
              title: 'Thumbnail',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'live-streaming-thumbnails'
              }
            },
            {
              title: 'Starting Soon Screen',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'live-streaming-starting-soon'
              }
            },
            {
              title: 'Live Stream Intro',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'live-streaming-intro'
              }
            }
          ]
        },
        {
          title: 'Social Media Templates',
          route: VIDEO_TEMPLATE_ROUTE,
          params: {
            pageName: 'social-media'
          },
          links: [
            {
              title: 'YouTube Video',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'social-media-youtube'
              }
            },
            {
              title: 'Facebook Video',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'social-media-facebook'
              }
            },
            {
              title: 'Instagram Video',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'social-media-instagram'
              }
            },
            {
              title: 'Facebook Cover Image',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'image-facebook-cover'
              }
            },
            {
              title: 'Reels & Stories',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'social-media-stories'
              }
            }
          ]
        },
        {
          title: 'Ads & Promo',
          route: VIDEO_TEMPLATE_ROUTE,
          params: {
            pageName: 'ads-promo'
          },
          links: [
            {
              title: 'Video Ad Templates',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'ads-promo-ads'
              }
            },
            {
              title: 'Promo Video Templates',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'ads-promo-promo'
              }
            },
            {
              title: 'News Video Templates',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'use-cases-news'
              }
            },
            {
              title: 'Testimonials',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'ads-promo-testimonials'
              }
            },
            {
              title: 'Video Quotes',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'use-cases-quotes'
              }
            }
          ]
        },
        {
          title: 'Trending Templates',
          route: VIDEO_TEMPLATE_ROUTE,
          footerButton: {
            title: 'See all templates',
            href: `${waveUrl}video-templates`
          },
          params: {
            pageName: 'trending'
          },
          links: [
            {
              title: 'Collage Videos',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'use-cases-collage'
              }
            },
            {
              title: 'Zoom Virtual Backgrounds',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'use-cases-zoom-backgrounds'
              }
            },
            {
              title: 'Holiday Videos',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'holidays'
              }
            },
            {
              title: 'Frame Videos',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'use-cases-frame'
              }
            },
            {
              title: 'Video Intro & Outro',
              route: VIDEO_TEMPLATE_ROUTE,
              params: {
                pageName: 'use-cases-intro-outro'
              }
            }
          ]
        }
      ]
    },
    {
      title: 'Creative assets',
      isDropdownWithPreview: true,
      children: [
        {
          title: 'Stock libraries',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}assets/stock`
          },
          iconSrc: 'images/icons/header/stock-libraries_v1.jpg',
          links: [
            {
              title: 'Free stock video',
              href: `${waveUrl}assets/stock/free-videos`
            },
            {
              title: 'Royalty-free music',
              href: `${waveUrl}assets/stock/free-music`
            },
            {
              title: 'Free stock images',
              href: `${waveUrl}assets/stock/free-images`
            }
          ]
        },
        {
          title: 'Visual effects',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}assets/effects`
          },
          iconSrc: 'images/icons/header/visual-effects_v1.jpg',
          links: [
            {
              title: 'Video filters',
              href: `${waveUrl}assets/effects/video-filters`
            },
            {
              title: 'Video overlays',
              href: `${waveUrl}assets/effects/video-overlays`
            },
            {
              title: 'Video transition',
              href: `${waveUrl}assets/effects/video-transitions`
            },
            {
              title: 'Animated text',
              href: `${waveUrl}assets/effects/animated-text`
            }
          ]
        },
        {
          title: 'Graphic elements',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}assets/graphics`
          },
          iconSrc: 'images/icons/header/graphic-elements_v1.jpg',
          links: [
            {
              title: 'Video thumbnail',
              href: `${waveUrl}assets/graphics/thumbnail`
            },
            {
              title: 'Lower third',
              href: `${waveUrl}assets/graphics/lower-third`
            },
            {
              title: 'Video intro',
              href: `${waveUrl}assets/effects/video-intro-maker`
            },
            {
              title: 'Frame video',
              href: `${waveUrl}assets/graphics/frame`
            }
          ]
        }
      ]
    },
    {
      title: 'Tools',
      isArrows: true,
      contentMaxWidth: 971,
      isDropdownWithPreview: true,
      children: [
        {
          title: 'Online video editing',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/video-editing`
          },
          iconSrc: 'images/icons/header/video-editing_v1.jpg',
          links: [
            {
              title: 'Online video maker',
              href: `${waveUrl}online-video-maker`
            },
            {
              title: 'Combine video clips',
              href: `${waveUrl}tools/video-editing/merge-video-clips`
            },
            {
              title: 'Animated text generator',
              href: `${waveUrl}assets/effects/animated-text`
            },
            {
              title: 'Video collage maker',
              href: `${waveUrl}tools/video-editing/video-collage-maker`
            },
            {
              title: 'GIF maker',
              href: `${waveUrl}tools/video-editing/gif-maker`
            }
          ]
        },
        {
          title: 'Audio editing',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/audio-editing`
          },
          iconSrc: 'images/icons/header/speech-audio_v1.jpg',
          links: [
            {
              title: 'Add music to video',
              href: `${waveUrl}tools/audio-editing/add-music-to-video`
            },
            {
              title: 'Automatic captions',
              href: `${waveUrl}video-captioning`
            },
            {
              title: 'Text to speech',
              href: `${waveUrl}tools/audio-editing/text-to-speech`
            },
            {
              title: 'Video voiceover',
              href: `${waveUrl}tools/audio-editing/voiceover`
            },
            {
              title: 'Subtitler',
              href: `${waveUrl}tools/audio-editing/subtitler`
            }
          ]
        },
        {
          title: 'Video marketing tools',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/video-marketing`
          },
          iconSrc: 'images/icons/header/video-marketing_v1.jpg',
          links: [
            {
              title: 'Convert text to video with AI',
              href: `${waveUrl}tools/video-marketing/turn-text-into-video`
            },
            {
              title: 'Video ad maker',
              href: `${waveUrl}ad-maker`
            },
            {
              title: 'Make videos for Instagram',
              href: `${waveUrl}tools/video-marketing/instagram`
            },
            {
              title: 'Make videos for YouTube',
              href: `${waveUrl}tools/video-marketing/make-youtube-videos`
            },
            {
              title: 'Meme maker',
              href: `${waveUrl}tools/video-editing/meme-maker`
            }
          ]
        },
        {
          title: 'Video hosting',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/video-hosting`
          },
          iconSrc: 'images/icons/header/hosting_v1.jpg',
          links: [
            {
              title: 'Free video hosting',
              href: `${waveUrl}tools/video-hosting/free-video-hosting`
            },
            {
              title: 'Embed video ',
              href: `${waveUrl}tools/video-hosting/embed-video`
            },
            {
              title: 'Password protect video ',
              href: `${waveUrl}tools/video-hosting/password-protect-video`
            },
            {
              title: 'Branded video player ',
              href: `${waveUrl}tools/video-hosting/branded-video-player`
            },
            {
              title: 'Send video in e-mail',
              href: `${waveUrl}tools/video-marketing/video-email`
            }
          ]
        },
        {
          title: 'Converters',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/convert`
          },
          iconSrc: 'images/icons/header/converters.png',
          links: [
            {
              title: 'YouTube to MP4 converter',
              href: `${waveUrl}convert/youtube-to-mp4`
            },
            {
              title: 'YouTube to MP3 converter',
              href: `${waveUrl}convert/youtube-to-mp3`
            },
            {
              title: 'Instagram to MP4 converter',
              href: `${waveUrl}convert/instagram-to-mp4`
            },
            {
              title: 'Instagram video downloader',
              href: `${waveUrl}convert/instagram-video-downloader`
            }
          ]
        }
      ]
    },
    {
      title: 'Resources',
      children: [
        {
          title: 'Video Marketing Blog',
          href: `${waveUrl}blog/`
        },
        {
          title: 'Live Better show',
          href: `${waveUrl}blog/live-better-show/`
        },
        {
          title: 'Knowledge Base',
          href: `${waveUrl}help/`,
          target: '_blank'
        },
        {
          title: 'Video Tutorials',
          href: `${waveUrl}tutorial`
        },
        {
          title: 'Facebook Community',
          href: 'https://www.facebook.com/groups/wave.video/',
          target: '_blank'
        },
        {
          title: 'Affiliate Program',
          href: 'https://animatron.tapfiliate.com/',
          target: '_blank'
        },
        {
          title: 'Content Calendar',
          href: isUserLogged ? `${waveUrl}calendar` : `${waveUrl}social-calendar`
        }
      ]
    },
    {
      title: 'Pricing',
      route: 'pricing'
    }
  ].filter(v => v) as NavigationLinkWithNested[]
});

const studioLinks = {
  footerLinks: [
    {
      menuTitle: 'company',
      links: [
        {
          title: 'About',
          href: `${animatronUrl}company/about`
        },
        {
          title: 'Privacy Policy',
          href: `${studioUrl}privacy`
        },
        {
          title: 'Terms of Use',
          href: `${studioUrl}terms-of-service`
        },
        {
          title: 'Contact Us',
          onClick: intercom.openChat
        },
        {
          title: 'Jobs',
          href: 'https://www.notion.so/Wave-video-Jobs-40a951c3496141e7a1506e48426ef478',
          target: '_blank'
        }
      ]
    },
    {
      menuTitle: 'products',
      links: [
        {
          title: 'Animatron Studio',
          href: studioUrl
        },
        {
          title: 'Video Editor',
          href: waveUrl
        },
        {
          title: 'Video Hosting',
          href: `${waveUrl}tools/video-hosting/free-video-hosting`
        },
        {
          title: 'Content Calendar',
          href: `${waveUrl}social-calendar`
        },
        {
          title: 'Automatic Captions',
          href: `${waveUrl}video-captioning`
        },
        {
          title: 'Free Stock Library',
          href: `${waveUrl}assets/stock/free-videos`
        }
      ]
    },
    {
      menuTitle: 'Solutions',
      links: [
        {
          title: 'Presentation Maker',
          href: `${studioUrl}presentation-maker`
        },
        {
          title: 'Plans and Pricing',
          href: `${studioUrl}pricing`
        },
        {
          title: 'HTML5 Banner Maker',
          href: `${studioUrl}html5-banner-maker`
        },
        {
          title: 'Explainer Video Maker',
          href: `${studioUrl}explainer-video-maker`
        },
        {
          title: 'Education',
          href: `${studioUrl}education/week1`
        },
        {
          title: 'Agencies',
          href: `${studioUrl}animation-for-agencies`
        }
      ]
    },
    {
      menuTitle: 'Support',
      links: [
        {
          title: 'Knowledge Base',
          href: 'https://kb.animatron.com/',
          target: '_blank'
        },
        {
          title: 'Tutorials',
          href: `${studioUrl}tutorial`
        },
        {
          title: 'Blog',
          href: `${animatronUrl}blog`
        }
      ]
    }
  ],
  navList: [
    {
      title: 'Explore',
      href: `${studioUrl}explore/staff-picks`
    },
    {
      title: 'Pricing',
      href: `${studioUrl}pricing`
    },
    {
      title: 'Lite Mode',
      href: `${studioUrl}lite-mode`
    },
    {
      title: 'Tutorials',
      href: `${studioUrl}tutorial`
    },
    {
      title: 'More',
      children: [
        {
          title: 'Help',
          href: `https://kb.animatron.com/`
        },
        {
          title: 'Blog',
          href: `${animatronUrl}blog`
        },
        {
          title: 'Education',
          href: `${studioUrl}education/week1`
        },
        {
          title: 'Company',
          href: `${animatronUrl}company`
        }
      ]
    }
  ]
};

const getProductLinkMap = (props: LinkMapProps) => ({
  WAVE: getWaveLinks(props),
  STUDIO: studioLinks
});

const getLinksByProduct = ({ product, ...otherProps }: LinksProps) => {
  const productLinkMap = getProductLinkMap(otherProps);
  return productLinkMap[product];
};

const useNavigation = () => {
  const isUserLogged = useSelector(authSelectors.isUserLogged);
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);

  return {
    ...getLinksByProduct({
      product,
      isUserLogged
    })
  };
};

export default useNavigation;
