import compose from 'lodash/fp/compose';
import pick from 'lodash/fp/pick';
import values from 'lodash/fp/values';

import getStudioLinks, { StudioLinks, StudioLinkKeys } from './getStudioLinks';

const getStudioSocialLinks = (studioUrl: string) =>
  compose(
    values,
    pick<StudioLinks, StudioLinkKeys>([
      'socialGithub',
      'socialTwitter',
      'socialFacebook',
      'socialYoutube'
    ])
  )(getStudioLinks(studioUrl));

export default getStudioSocialLinks
