import { UserMenuDropdown, UserMenuHeader } from '@anm/components/header';
import { DEFAULT_DROPDOWN_DELAY } from '@anm/constants/delays';
import { FC } from 'react';

import UserAvatar from '../../UserAvatar';
import UserMenuLinkList from '../../user/UserMenuLinkList';

type UserDropdownMenuProps = {
  email: string;
  displayName: string;
};

const UserDropdownMenu: FC<UserDropdownMenuProps> = ({
  email,
  displayName
}) => (
  <UserMenuDropdown
    openDelay={DEFAULT_DROPDOWN_DELAY}
    closeDelay={DEFAULT_DROPDOWN_DELAY}
    direction="to-bottom-left-with-arrow"
    openerVariant="black"
    renderContent={() => (
      <UserMenuLinkList>
        <UserMenuHeader>
          <div>{displayName}</div>
          <div>{email}</div>
        </UserMenuHeader>
      </UserMenuLinkList>
    )}
  >
    <UserAvatar />
  </UserMenuDropdown>
);
export default UserDropdownMenu;
